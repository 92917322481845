export const SvgImageToString = () => {
  return (
    <div>
      <div style={{ display: 'none' }}>
        <svg
          id='xx'
          width='19'
          height='18'
          viewBox='0 0 19 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M9.38954 0.818237L12.0381 6.20396L17.961 7.0729L13.6753 11.2627L14.6867 17.1819L9.38954 14.3858L4.0924 17.1819L5.10383 11.2627L0.818115 7.0729L6.74097 6.20396L9.38954 0.818237Z'
            stroke='#005DBF'
          />
        </svg>
      </div>
    </div>
  );
};
