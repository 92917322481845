//import SASS && Mui material && icons
import { Box } from '@mui/material';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import Select from 'react-select';
import tableStyle from '../../../components/Layouts/tableStyle.module.scss';

//import Icons
import NumberFormat from 'react-number-format';
import modalClose from '../../../assets/img/icons/modalClose.svg';

import { formatNumberWithoutDecimals, getLocaleSeparators } from '../../../helpers/numberFormatting';

const localeSeparators = getLocaleSeparators

export const CardVirtualCoins = ({ options, onChange, src, onClose, credit }) => {
  return (
    <div className={`${tableStyle.cardCoins}`}>
      <img
        src={modalClose}
        alt='close'
        style={{ position: 'absolute', right: '3px', top: '3px' }}
        className={`${tableStyle.styleIconCloseFile} pointer`}
        onClick={() => onClose()}
        height={22}
      />
      <div className={`${tableStyle.cardCoinsImg}`}>
        <img className='w-100' height={50} src={src} alt='moneySymbol' />
      </div>

      <div className={`${tableStyle.grid2} mb-2`}>
        <Box display={'grid'} gap={1}>
          <Box>
            <Box className={`m-0 ${tableStyle.ordDarkGrayText} ${tableStyle.f12}`}>
              &nbsp;Tipo de recaudo
            </Box>

            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={options}
              className='text-secondary w-100'
              placeholder={'Seleccionar...'}
              styles={ordCustomSelect}
              onChange={e => {
                onChange({ label: e.label, value: e.value });
              }}
            />
          </Box>

          <Box>
            <Box className={`m-0 ${tableStyle.ordDarkGrayText} ${tableStyle.f12}`}>
              &nbsp;{credit ? 'Franquicia' : 'Banco'}
              {/* <span className={`${tableStyle.ordOrangeText}`}>*</span> */}
            </Box>

            <Select
              noOptionsMessage={() => 'No hay datos'}
              className='text-secondary w-100'
              placeholder={'Seleccionar...'}
              styles={ordCustomSelect}
            />
          </Box>
        </Box>

        <Box display={'grid'} gap={1}>
          <Box>
            <Box className={`m-0 ${tableStyle.ordDarkGrayText} ${tableStyle.f12}`}>
              &nbsp;Monto pagado
            </Box>

            <NumberFormat
              allowNegative={false}
              className={'ordInputAdmission w-100'}
              placeholder='Escribe...'
              isNumericString={true}
              maxLength={12}
              value={1500}
              thousandSeparator={localeSeparators.groupSeparator}
              decimalSeparator={localeSeparators.decimalSeparator}
              format={(valueStr) => {
                if (!valueStr) return ''

                return formatNumberWithoutDecimals(valueStr)
              }}
              allowLeadingZeros={false}
            />
          </Box>

          <Box>
            <Box>
              <Box className={`m-0 ${tableStyle.ordDarkGrayText} ${tableStyle.f12}`}>
                &nbsp;No. referencia
              </Box>

              <Select
                noOptionsMessage={() => 'No hay datos'}
                className='text-secondary w-100'
                placeholder={'Seleccionar...'}
                styles={ordCustomSelect}
              />
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};
