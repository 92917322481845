// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stylesOrders_w90px__2lFve {\n  width: 90px !important;\n}\n\n.stylesOrders_w100px__2cwqd {\n  width: 100px !important;\n}\n\n.stylesOrders_w110px__3rb_- {\n  width: 110px !important;\n}\n\n.stylesOrders_w120px__2TwYG {\n  width: 120px !important;\n}\n\n.stylesOrders_w140px__3cjzL {\n  width: 140px !important;\n}\n\n.stylesOrders_w160px__Ph-EI {\n  width: 160px !important;\n}", "",{"version":3,"sources":["webpack://src/components/Containers/admision/orders/stylesOrders.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AACA;EACE,uBAAA;AAEF","sourcesContent":[".w90px{\n  width: 90px !important;\n}\n\n.w100px{\n  width: 100px !important;\n}\n\n.w110px{\n  width: 110px !important;\n}\n\n.w120px{\n  width: 120px !important;\n}\n\n.w140px{\n  width: 140px !important;\n}\n.w160px{\n  width: 160px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"w90px": "stylesOrders_w90px__2lFve",
	"w100px": "stylesOrders_w100px__2cwqd",
	"w110px": "stylesOrders_w110px__3rb_-",
	"w120px": "stylesOrders_w120px__2TwYG",
	"w140px": "stylesOrders_w140px__3cjzL",
	"w160px": "stylesOrders_w160px__Ph-EI"
};
export default ___CSS_LOADER_EXPORT___;
