//components
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import OrdModal from '../../../../OrderingModule/OrderingComponents/OrdModal';

//styles
import styles from '../styles.module.scss';
import tableStyles from '../../../../components/Layouts/tableStyle.module.scss';
import { appCustomSelectNew } from '../../../../components/Layouts/react-select-custom';

export const ExtraCup = ({
  show,
  btnYesEventModal,
  btnNoEventModal,
  onHideModal,
  options,
  valueService,
  onChangeModalService,
  valueTextarea,
  onChangeTextarea,
}) => {
  return (
    <OrdModal
      size={400}
      show={show}
      title={'Solicitar cupo extra'}
      btnYesName={'Solicitar'}
      btnNoName={'Cancelar'}
      onHide={onHideModal}
      btnYesEvent={btnYesEventModal}
      btnNoEvent={btnNoEventModal}
      sizeBody={`${tableStyles.modalHeight}`}
    >
      <div>
        <Form.Group controlId='servicio' className='py-2'>
          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
            <b className={`${styles.app_title} px-1`}>Servicio</b>
          </Form.Label>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            styles={appCustomSelectNew}
            options={options}
            value={valueService}
            onChange={onChangeModalService}
          />
        </Form.Group>

        <Form.Group controlId='servicio' className='py-2'>
          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
            <b className={`${styles.app_title} px-1`}>Comentarios</b>
          </Form.Label>
          <Form.Control
            as='textarea'
            rows={3}
            className={`ord-roundInput `}
            value={valueTextarea}
            onChange={onChangeTextarea}
          />
        </Form.Group>
      </div>
    </OrdModal>
  );
};
