export const CheckPrint = () => {
  return (
    <div
      style={{
        height: '20px',
        width: '20px',
        border: `2px solid #00b4cc`,
        borderRadius: '5px',
      }}
    />
  );
};
