// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tmAdmissionStyles_tmAdmissionSettingsContainer__28OkD {\n  display: grid;\n  width: 100vw;\n  grid-template-areas: \"content\" \"action-buttons\";\n  grid-template-rows: 1fr auto;\n}\n\n.tmAdmissionStyles_tmAdmissionSettingsContent__3c8YH {\n  grid-area: content;\n  overflow-y: auto;\n  scrollbar-gutter: stable;\n  height: calc(100% - 100px);\n  padding-bottom: 50px;\n}\n\n.tmAdmissionStyles_tmAdmissionSettingsActionsButtonsContainer__3O6Vp {\n  grid-area: action-buttons;\n  max-height: 100px;\n}", "",{"version":3,"sources":["webpack://src/components/Containers/asistencial/tmAdmission/settings/tmAdmissionStyles.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EAEA,+CACI;EAEJ,4BAAA;AAFJ;;AAKA;EACI,kBAAA;EACA,gBAAA;EACA,wBAAA;EACA,0BAAA;EACA,oBAAA;AAFJ;;AAKA;EACI,yBAAA;EACA,iBAAA;AAFJ","sourcesContent":[".tmAdmissionSettingsContainer {\n    display: grid;\n    width: 100vw;\n\n    grid-template-areas:\n        \"content\"\n        \"action-buttons\";\n    grid-template-rows: 1fr auto;\n}\n\n.tmAdmissionSettingsContent {\n    grid-area: content;\n    overflow-y: auto;\n    scrollbar-gutter: stable;\n    height: calc(100% - 100px);\n    padding-bottom: 50px;\n}\n\n.tmAdmissionSettingsActionsButtonsContainer {\n    grid-area: action-buttons;\n    max-height: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tmAdmissionSettingsContainer": "tmAdmissionStyles_tmAdmissionSettingsContainer__28OkD",
	"tmAdmissionSettingsContent": "tmAdmissionStyles_tmAdmissionSettingsContent__3c8YH",
	"tmAdmissionSettingsActionsButtonsContainer": "tmAdmissionStyles_tmAdmissionSettingsActionsButtonsContainer__3O6Vp"
};
export default ___CSS_LOADER_EXPORT___;
