//import SASS && Mui material && icons
import { Box } from '@mui/material';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import Select from 'react-select';
import tableStyle from '../../../components/Layouts/tableStyle.module.scss';

//import Icons
import moneySymbol from '../../../assets/img/icons/moneySymbol.svg';
import modalClose from '../../../assets/img/icons/modalClose.svg';
import NumberFormat from 'react-number-format';

export const CardCoins = ({ onClose }) => {
  return (
    <div className={`${tableStyle.cardCoins}`}>
      <img
        src={modalClose}
        alt='close'
        style={{ position: 'absolute', right: '3px', top: '3px' }}
        className={`${tableStyle.styleIconCloseFile} pointer`}
        onClick={() => onClose()}
        height={22}
      />

      <div className={`${tableStyle.cardCoinsImg}`}>
        <img className='w-100' height={60} src={moneySymbol} alt='moneySymbol' />
      </div>

      <div>
        <div className={`${tableStyle.grid2} mb-2`}>
          <Box>
            <Box className={`m-0 ${tableStyle.ordDarkGrayText} ${tableStyle.f12}`}>
              &nbsp;Tipo de recaudo
            </Box>

            <Select
              noOptionsMessage={() => 'No hay datos'}
              className='text-secondary w-100'
              placeholder={'Seleccionar...'}
              styles={ordCustomSelect}
            />
          </Box>

          <Box>
            <Box className={`m-0 ${tableStyle.ordDarkGrayText} ${tableStyle.f12}`}>
              &nbsp;Monto pagado
            </Box>

            <NumberFormat
              allowNegative={false}
              className={'ordInputAdmission w-100'}
              decimalSeparator={'.'}
              placeholder='Escribe...'
              isNumericString={true}
              maxLength={12}
              prefix={'$'}
              thousandSeparator={','}
              thousandsGroupStyle='thousand'
              value={1500}
            />
          </Box>
        </div>

        <div className={`${tableStyle.grid4} mb-2`}>
          <Box style={{ gap: '7px' }} display={'grid'} gridTemplateColumns='1fr 1fr'>
            <Box>
              <Box className={`m-0 ${tableStyle.ordDarkGrayText} ${tableStyle.f12}`}>
                &nbsp;Vuelto total
              </Box>

              <NumberFormat
                allowNegative={false}
                className={'ordInputAdmission w-100'}
                decimalSeparator={'.'}
                placeholder='Escribe...'
                isNumericString={true}
                maxLength={12}
                prefix={'$'}
                thousandSeparator={','}
                thousandsGroupStyle='thousand'
                value={1500}
              />
            </Box>

            <Box>
              <Box className={`m-0 ${tableStyle.ordDarkGrayText} ${tableStyle.f12}`}>
                &nbsp;¿Donar el vuelto?
              </Box>

              <Select
                noOptionsMessage={() => 'No hay datos'}
                className='text-secondary '
                placeholder={'Seleccionar...'}
                styles={ordCustomSelect}
              />
            </Box>
          </Box>

          <Box style={{ gap: '7px' }} display={'grid'} gridTemplateColumns='1fr 1fr'>
            <Box>
              <Box className={`m-0 ${tableStyle.ordDarkGrayText} ${tableStyle.f12}`}>
                &nbsp;¿cuanto?
              </Box>

              <Select
                noOptionsMessage={() => 'No hay datos'}
                className='text-secondary '
                placeholder={'Seleccionar...'}
                styles={ordCustomSelect}
              />
            </Box>

            <Box>
              <Box className={`m-0 ${tableStyle.ordDarkGrayText} ${tableStyle.f12}`}>
                &nbsp;¿Vuelto entregado?
              </Box>

              <Select
                noOptionsMessage={() => 'No hay datos'}
                className='text-secondary '
                placeholder={'Seleccionar...'}
                styles={ordCustomSelect}
              />
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};
